@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
font-family: "Montserrat Alternates", sans-serif;
font-family: "Lato", sans-serif;
font-family: "Roboto Flex", sans-serif;

Montserrat : heading
Lato : subheading and Paragraph
Roboto: Table,etc


*/

* {
  background-color: "#ffffff";
  font-family: "lato";
}

@layer base {
  html {
    scroll-behavior: smooth;
    background-color: "#ffffff";
  }
  h1 {
    font-family: "Montserrat Alternates";
    font-weight: 900;
    font-size: xx-large;
  }
  h3 {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: xx-large;
  }
}

.sticky {
  @apply fixed top-0 w-full;
}

@layer components {
  .gradient_color {
    @apply bg-gradient-to-r from-primary via-secondary to-ternary;
  }
}

@layer utilities {
  .square {
    aspect-ratio: 1/1;
  }
  .btn {
    @apply px-4  py-2 shadow-2xl rounded-md hover:scale-105 duration-100;
  }
  .btn-admin {
    @apply px-4 bg-gradient-to-r hover:bg-gradient-to-l  from-primary to-secondary py-2 shadow-2xl text-white rounded-md hover:scale-110 duration-300;
  }
}

/* .active {
  @apply text-green-600 scale-110;
} */
